.nav-styles {
    font-size: 10px
}

.sidebar-wrapper {
    position: fixed;
    height: 100vh;
    top: 0;
    width: 10rem;
    overflow-y: auto;
}

.sidebar-wrapper .side-navigation .side-navigation-nav-link {
    cursor: pointer;
}

.sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
}

.logo {
    width: 7.5rem;
    margin-top: 1rem;
}

.link {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0.5rem 0rem 0.5rem .25rem;
}

.link-text {
    font-size: 16px;
}

.link.text-primary {
    color: #00aeff !important;
    transition: color 0.6s;
}