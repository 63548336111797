@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Poppins Regular");
  src: url("./assets/fonts/poppins-v6-latin-regular.eot"), /* IE9 Compat Modes */
  url("./assets/fonts/poppins-v6-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./assets/fonts/poppins-v6-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */
  url("./assets/fonts/poppins-v6-latin-regular.woff") format("woff"), /* Modern Browsers */
  url("./assets/fonts/poppins-v6-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./assets/fonts/poppins-v6-latin-regular.svg#Poppins") format("svg");
  /* Legacy iOS */
  unicode-range: U+0020-007F;
}

/* poppins-700 - latin */

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Poppins Bold");
  src: url("./assets/fonts/poppins-v6-latin-700.eot"), /* IE9 Compat Modes */
  url("./assets/fonts/poppins-v6-latin-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("./assets/fonts/poppins-v6-latin-700.woff2") format("woff2"), /* Super Modern Browsers */
  url("./assets/fonts/poppins-v6-latin-700.woff") format("woff"), /* Modern Browsers */
  url("./assets/fonts/poppins-v6-latin-700.ttf") format("truetype"), /* Safari, Android, iOS */
  url("./assets/fonts/poppins-v6-latin-700.svg#Poppins") format("svg");
  /* Legacy iOS */
  unicode-range: U+0020-007F;
}

html, body, #root {
  height: 100%;
  font-family: "Poppins", sans-serif !important;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f7fc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.form-control {
  height: 2rem !important;
  border-radius: 0 !important;
}

.dropdown-toggle::after {
  vertical-align: 0.155em !important;
}

.action-btn-container .action-btn {
  display: inline-block;
  cursor: pointer;
  width: 1.9rem;
  height: 1.9rem;
  text-align: center;
  overflow: hidden;
  transition: all 0.3s ease-out;
  white-space: nowrap;
}

.action-btn-container .action-btn:hover {
  width: 10rem;
}

.date-modal {
  position: absolute !important;
  top: 8rem !important;
  left: 20rem !important;
}

.date-modal .modal-dialog {
  /* Width */
  max-width: 100%;
  width: auto !important;
  display: inline-block;
}

.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.modal-backdrop.show {
  opacity: 0.95 !important;
}

.modal-dialog {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.modal-content {
  background-color: #fff !important;
  border-radius: 1rem !important;
  border: 0px solid rgba(255, 255, 255, 0) !important;
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.04) !important;
}

.modal-header {
  border: 0 !important;
  border-radius: 0 !important;
}

.modal-footer {
  background-color: #0000 !important;
  z-index: 1;
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.nav-pills .nav-link.active, .show>.nav-pills .nav-link {
  color: #00aeff !important;
  background-color: #0000 !important;
  font-weight: bold !important;
}

.ReactVirtualized__Table__row {
  padding: 0.75rem !important;
}

.cursor-pointer-hover:hover {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0) !important;
}

:focus {
  outline: transparent !important;
}

.wizard-container .nav-item a.checked, .wizard-container .nav-item a.active {
  background-color: #00aeff !important;
  color: #fff !important;
}

select.form-control {
  height: 2.5rem!important;
}

.form-control {
  font-size: 0.85rem !important;
  height: 2.37rem !important;
}

.intl-tel-input .country-list {
  z-index: 3 !important;
}

a, p {
  font-size: 0.85rem !important;
}

a, .nav-item {
  font-size: 16px !important;
}

.dropdown-item {
  font-size: 0.85rem !important;
}

.drawer {
  box-shadow: 0 .5rem 2rem -17px rgba(0, 0, 0, .11) !important
}

.side-navigation-nav-link.active {
  border: 0px solid #0000 !important;
}

.rbt-input-hint {
  top: 1px !important;
}

.badge-green {
  color: var(--green) !important;
}

.badge-red {
  color: var(--red) !important;
}

.member-segment-button {
  font-size: 0.875rem !important;
  cursor: pointer;
}

.filter-preview {
  background-color: #389bff4d !important;
  color: #00aeff;
  border-radius: 1rem;
}